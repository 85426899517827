var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c(
            "v-btn",
            {
              staticClass: "justify-start",
              attrs: { disabled: _vm.currentTables.length === 0 },
              on: {
                click: function ($event) {
                  return _vm.addToStaging(_vm.currentTables)
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("share")]),
              _vm._v(" stage all (" + _vm._s(_vm.currentTables.length) + ") "),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mx-3", attrs: { vertical: "" } }),
          _vm.selected.length > 0
            ? _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.addToStaging(_vm.selected)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("share")]),
                  _vm._v(
                    " stage selected (" + _vm._s(_vm.selected.length) + ") "
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "", bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "caption mr-2",
                            attrs: { color: "secondary", icon: "", dark: "" },
                          },
                          on
                        ),
                        [_c("v-icon", [_vm._v("more_vert")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                { staticClass: "py-4", attrs: { nav: "", dense: "" } },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "justify-start",
                          attrs: {
                            disabled: _vm.currentTables.length === 0,
                            block: "",
                            text: "",
                            color: "secondary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addToStaging(_vm.currentTables)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("share"),
                          ]),
                          _vm._v(
                            " stage all (" +
                              _vm._s(_vm.currentTables.length) +
                              ") "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "justify-start",
                          attrs: {
                            disabled: _vm.selected.length === 0,
                            block: "",
                            text: "",
                            color: "secondary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addToStaging(_vm.selected)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("share"),
                          ]),
                          _vm._v(
                            " stage selected (" +
                              _vm._s(_vm.selected.length) +
                              ") "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "my-3" }),
                  _c(
                    "v-list-item",
                    [
                      _c("delete-button", {
                        attrs: {
                          objectType: "table",
                          icon: false,
                          disabled: _vm.selected.length === 0,
                          buttonName: "Delete",
                          apiURL: `/instances/${_vm.$route.params.iid}/tables/delete`,
                          protocol: "POST",
                          selected: _vm.selected,
                          isAsyncDeletion: true,
                          showDialog: true,
                          objectName: "Tables",
                          warningText: `Are you sure you want to delete the selected tables permanently?`,
                        },
                        on: {
                          error: function ($event) {
                            return _vm.errorMessage($event.error)
                          },
                          deleting: function ($event) {
                            return _vm.emptySelected(!$event.value)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          _c("v-text-field", {
            staticClass: "mr-2",
            attrs: {
              "prepend-inner-icon": "mdi-filter",
              autofocus: "",
              "hide-details": "",
              solo: "",
              flat: "",
              "background-color": "grey lighten-4",
              dense: "",
              label: "Filter tables...",
              clearable: "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: { icon: "", loading: _vm.tablesFetching },
                            on: {
                              click: function ($event) {
                                return _vm.$store.dispatch(
                                  "snapshotStore/fetchCurrentTables",
                                  _vm.$route.params.snid
                                )
                              },
                            },
                          },
                          on
                        ),
                        [_c("v-icon", [_vm._v("refresh")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Refresh Tables")])]
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "shepherd-table-staging-step-1",
        attrs: {
          search: _vm.search,
          "item-key": "tid",
          headers: _vm.headers,
          options: _vm.tableOptions,
          "show-select": "",
          items: _vm.currentTables,
          "footer-props": { "items-per-page-options": _vm.itemsPerPageOptions },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function ({ pagination, options, updateOptions }) {
                return [
                  _c("v-data-footer", {
                    staticClass: "no-border",
                    attrs: {
                      pagination: pagination,
                      options: options,
                      itemsPerPageOptions: _vm.itemsPerPageOptions,
                      "items-per-page-text":
                        "$vuetify.dataTable.itemsPerPageText",
                    },
                    on: { "update:options": updateOptions },
                  }),
                ]
              },
            },
            {
              key: `item.short_id`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "text-none",
                                      attrs: { text: "" },
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(" " + _vm._s(item.short_id) + " "),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "showOnHover",
                                        attrs: { right: "" },
                                      },
                                      [_vm._v("mdi-chevron-down")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "py-4", attrs: { nav: "", dense: "" } },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "justify-start",
                                      attrs: {
                                        color: "secondary",
                                        text: "",
                                        block: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setQueryParam(
                                            item.short_id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("filter_alt"),
                                      ]),
                                      _vm._v(" Query table "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "justify-start",
                                      attrs: {
                                        color: "secondary",
                                        text: "",
                                        block: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exploreTable(
                                            item.short_id,
                                            item.tid,
                                            "columnList"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("zoom_in"),
                                      ]),
                                      _vm._v(" Explore columns "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "justify-start",
                                      attrs: {
                                        color: "secondary",
                                        text: "",
                                        block: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exploreTable(
                                            item.short_id,
                                            item.tid,
                                            "dataPreview"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-playlist-play"),
                                      ]),
                                      _vm._v(" Preview table "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("copy-to-clipboard", {
                            attrs: {
                              textToCopy: _vm.quotedName(item.short_id),
                              buttonName: "Copy name to clipboard",
                              type: "listItem",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.description`,
              fn: function ({ item }) {
                return [
                  item.description
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { "max-width": "700", bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c("span", _vm._g({}, on), [
                                      _vm._v(_vm._s(item.description)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v(_vm._s(item.description))])]
                      )
                    : _c("span", [_vm._v("No description available.")]),
                ]
              },
            },
            {
              key: `item.row_count`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.row_count
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.bytes`,
              fn: function ({ item }) {
                return [_vm._v(_vm._s(_vm.humanFileSize(item.bytes, false)))]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2 showOnHover",
                      attrs: {
                        color: "secondary",
                        small: "",
                        outlined: "",
                        text: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addToStaging([item])
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [_vm._v("share")]),
                      _vm._v(" stage "),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        small: "",
                        color: "primary",
                        "e2e-snapshot-tables-query": "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setQueryParam(item.short_id)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("filter_alt"),
                      ]),
                      _vm._v(" Query "),
                    ],
                    1
                  ),
                  _vm.isDevelopment
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            left: "",
                            "offset-x": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mr-3",
                                          attrs: {
                                            text: "",
                                            color: "secondary",
                                            small: "",
                                          },
                                        },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("more_horiz")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            {
                              staticClass: "py-4",
                              attrs: { nav: "", dense: "" },
                            },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c("rename-button", {
                                        attrs: {
                                          selected: [item],
                                          objectType: "table",
                                          dispatchFunction: () => {
                                            _vm.$store.dispatch(
                                              "snapshotStore/fetchCurrentTables",
                                              _vm.$route.params.snid
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c("edit-description-button", {
                                        attrs: {
                                          selected: [item],
                                          objectType: "table",
                                          dispatchFunction: () => {
                                            _vm.$store.dispatch(
                                              "snapshotStore/fetchCurrentTables",
                                              _vm.$route.params.snid
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider", { staticClass: "my-3" }),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c("delete-button", {
                                        attrs: {
                                          objectType: "table",
                                          icon: false,
                                          buttonName: "Delete",
                                          apiURL: `/instances/${_vm.$route.params.iid}/tables/delete`,
                                          protocol: "POST",
                                          selected: [item],
                                          isAsyncDeletion: true,
                                          showDialog: true,
                                          objectName: "Tables",
                                          warningText: `Are you sure you want to delete the selected tables permanently?`,
                                        },
                                        on: {
                                          error: function ($event) {
                                            return _vm.errorMessage(
                                              $event.error
                                            )
                                          },
                                          deleting: function ($event) {
                                            return _vm.emptySelected(
                                              !$event.value
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }